<template>
    <b-row class="m-1">
        <b-col cols="2" class="text-center mt-1">
            <b-avatar :src="review.advertiser.account.avatar" class="mb-1" size="100px"></b-avatar>
        </b-col>
        <b-col cols="9" class="m-1">
            <h5 class="ml-2">{{ review.advertiser.companyName }}</h5>
            <b-form-rating
                id="rating-lg-no-border"
                v-model="review.rating"
                no-border
                variant="warning"
                size="lg"
                inline
                class="m-0"
                readonly
            />
            <b-card-text class="ml-2 text-justify">{{ review.message }}</b-card-text>
        </b-col>
    </b-row>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormRating,
    BAvatar
} from 'bootstrap-vue'
export default {
    name: 'CardReview',
    props: ['review'],
    components: {
        BRow,
        BCol,
        BCard,
        BCardText,
        BFormRating,
        BAvatar
    },
    data() {
        return {
            value: 5
        }
    }
}
</script>