<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <b-card-header>
                    <b-button variant="outline-primary" disabled>
                        {{ campaign.status }}
                    </b-button>

                    <router-link :to="{name: 'campaign-edit-1', params: {id: $route.params.id}}" tag="button" class="btn float-right btn-primary" v-if="campaign.status == 'draft'">Edit Campaign</router-link>
                    <router-link :to="{name: 'campaign-list-applicant', params: {id: $route.params.id}}" tag="button" class="btn float-right btn-primary" v-if="campaign.status == 'on-broadcast'">Lihat Data Influencer</router-link> 
                </b-card-header>

                <b-card-body class="mt-1">
                    <h5>Informasi Campaign</h5>
                    <div style="text-align: center">
                        <b-img-lazy :src="campaign.banner" class="w-100" height="300px" style="object-fit: cover"/>
                    </div>

                    <b-row class="mt-1">
                        <b-col cols="6">
                            <b-row>
                                <b-col cols="6">Order ID</b-col>
                                <b-col cols="6">: {{ campaign.orderID }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">Nama Campaign</b-col>
                                <b-col cols="6">: {{ campaign.name }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">Status Penayangan</b-col>
                                <b-col cols="6">: {{ campaign.status }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">Jenis yang Dipromosikan</b-col>
                                <b-col cols="6">: {{ campaign.promotionType.name }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">Jenis Campaign</b-col>
                                <b-col cols="6">: {{ campaign.campaignType }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">Platform</b-col>
                                <b-col cols="6">: {{ campaign.platformType ? campaign.platformType.name : '-' }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">Layanan</b-col>
                                <b-col cols="6">: {{ campaign.services ? campaign.services.map(sv => sv.name).join(',') : '-' }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">Jumlah Post</b-col>
                                <b-col cols="6">: {{ campaign.postAmount }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">Harga Proyek</b-col>
                                <b-col cols="6">: {{ campaign.cost_cast }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">Tanggal Campaign Mulai</b-col>
                                <b-col cols="6">: {{ campaign.startDate_cast }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">Tanggal Campaign Selesai</b-col>
                                <b-col cols="6">: {{ campaign.endDate_cast }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">Tanggal Deadline Konten</b-col>
                                <b-col cols="6">: {{ campaign.contentDeadline_cast }}</b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="6">
                            <b-row>
                                <b-col cols="6">Deskripsi Campaign</b-col>
                                <b-col cols="6">: {{ campaign.description }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">Tipe Konten</b-col>
                                <b-col cols="6">: {{ campaign.contentType }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">Aturan Foto/Video</b-col>
                                <b-col cols="6">: {{ campaign.mediaGuideline }}</b-col>
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row class="mt-1">
                        <b-col cols="12">
                            <b-tabs>
                                <b-tab active title="Informasi Influencer">
                                    <b-card-text>
                                        <b-row>
                                            <b-col cols="2">Nama Influencer</b-col>
                                            <b-col cols="6">: {{ campaign.chosenInfluencer ? campaign.chosenInfluencer.firstName : '-' }}</b-col>
                                        </b-row>
                                    </b-card-text>
                                    <b-card-text>
                                        <b-row>
                                            <b-col cols="2">Status Influencer</b-col>
                                            <b-col cols="6">: {{ campaign.chosenInfluencer ? ( campaign.chosenInfluencer.account.verified ? 'Verified' : 'Not Verified' ) : '-' }}</b-col>
                                        </b-row>
                                    </b-card-text>
                                </b-tab>

                                <b-tab
                                title="Gdrive"
                                >
                                    <h5 class="mb-1">Upload Konten Gdrive</h5>
                                    <b-card v-if="contentProposal.length <= 0">
                                        <b-card-text class="text-center">
                                            Tidak ada data
                                        </b-card-text>
                                    </b-card>
                                    <b-card
                                        border-variant="secondary"
                                        bg-variant="transparent"
                                        class="shadow-none"
                                        v-for="(list, key) in contentProposal" :key="key"
                                        >
                                        <b-card-text>
                                            <b-row>
                                                <b-col cols="8">
                                                    <b-form @submit.prevent>
                                                        <b-row>
                                                            <b-col cols="4">
                                                                Tanggal
                                                            </b-col>
                                                            <b-col cols="8">
                                                                : {{ list.createdAt_cast }}
                                                            </b-col>

                                                            <b-col cols="4">
                                                                Link Konten
                                                            </b-col>
                                                            <b-col cols="8">
                                                                : <a :href="list.url" target="_blank">{{ list.url }}</a>
                                                            </b-col>
                                                        </b-row>
                                                    </b-form>
                                                </b-col>
                                                <b-col cols="4" class="text-center">
                                                    <span v-if="list.status == 'approved'">
                                                        <button disabled class="btn btn-outline-success">Disetujui</button>
                                                    </span>
                                                    <span v-else-if="list.status == 'revision'">
                                                        <button disabled class="btn btn-outline-secondary">Revisi</button>
                                                    </span>
                                                    <span v-else>
                                                        <button class="btn btn-primary mr-1" @click="approve(list.id)">Setujui</button>
                                                        <button class="btn btn-secondary" @click="revisi(list.id)">Revisi</button>
                                                    </span>
                                                </b-col>
                                            </b-row>
                                        </b-card-text>
                                    </b-card>
                                </b-tab>

                                <b-tab title="Bukti Tayang" v-if="campaign.status == 'on-progress' || campaign.status == 'finished'">
                                    <h6 class="text-center">{{ campaign.platformType.name }} Platform</h6>
                                    <b-tabs>
                                        <b-tab v-for="(service, key) in services" :title="service.name" :key="key">
                                            <b-card
                                                border-variant="secondary"
                                                bg-variant="transparent"
                                                class="shadow-none"
                                                :key="keyI"
                                                v-for="(insight, keyI) in service.insight"
                                                >
                                                <b-card-text>
                                                    <b-row>
                                                        <b-col cols="12">
                                                            <b-form @submit.prevent>
                                                                <b-row>
                                                                    <b-col class="mb-1" cols="12">Post ke {{ keyI+1 }}</b-col>
                                                                    <b-col cols="2">
                                                                        Media URL
                                                                    </b-col>
                                                                    <b-col cols="10">
                                                                        : <a href="javascript:;">{{ insight.url }}</a>
                                                                    </b-col>

                                                                    <b-col cols="2" v-if="campaign.platformType.id != 1">
                                                                        Caption
                                                                    </b-col>
                                                                    <b-col cols="10" v-if="campaign.platformType.id != 1">
                                                                        : {{ insight.caption }}
                                                                    </b-col>

                                                                    <b-col cols="12" class="mb-1"></b-col>


                                                                    <b-col cols="3">
                                                                        Comments
                                                                    </b-col>
                                                                    <b-col cols="3">
                                                                        : {{ insight.comment }}
                                                                    </b-col>

                                                                    <b-col cols="3" v-if="campaign.platformType.id != 1">
                                                                        {{ campaign.platformType.id == 2 ? 'Views' : 'Engagement' }}
                                                                    </b-col>
                                                                    <b-col cols="3" v-if="campaign.platformType.id != 1">
                                                                        : {{ insight.engagement }}
                                                                    </b-col>

                                                                    <b-col cols="3" v-if="campaign.platformType.id != 1">
                                                                        Likes
                                                                    </b-col>
                                                                    <b-col cols="3" v-if="campaign.platformType.id != 1">
                                                                        : {{ insight.like }}
                                                                    </b-col>

                                                                    <b-col cols="3" v-if="campaign.platformType.id != 2">
                                                                        Impression
                                                                    </b-col>
                                                                    <b-col cols="3" v-if="campaign.platformType.id != 2">
                                                                        : {{ insight.impression }}
                                                                    </b-col>

                                                                    <b-col cols="3" v-if="campaign.platformType.id < 2">
                                                                        Reach
                                                                    </b-col>
                                                                    <b-col cols="3" v-if="campaign.platformType.id < 2">
                                                                        : {{ insight.reach }}
                                                                    </b-col>

                                                                    <b-col cols="2"></b-col>
                                                                </b-row>
                                                            </b-form>
                                                        </b-col>
                                                    </b-row>
                                                </b-card-text>
                                            </b-card>
                                        </b-tab>
                                        
                                        <div class="text-center">
                                            <button class="btn btn-primary mr-1" @click="showModalSelesaiCampaign" v-if="campaign.status == 'on-progress'">Selesaikan Campaign</button>
                                            <button class="btn btn-primary mr-1" @click="showModalBeriUlasan" v-if="campaign.status == 'finished' && !review.rating">Beri Ulasan</button>
                                        </div>
                                    </b-tabs>
                                </b-tab>

                                <b-tab title="Review" v-if="review.rating">
                                    <CardReview :review="review"/>
                                </b-tab>
                            </b-tabs>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-col>

        <b-modal ref="my-modal-approve" @ok="setujuiKonten" title="Apakah anda yakin menyetujui seluruh konten?">
            <div class="d-block text-center">
                <b-form-textarea v-model="revisionNote"></b-form-textarea>
            </div>
        </b-modal>

        <b-modal ref="my-modal-revisi" @ok="revisiKonten" title="Apakah anda yakin buat revisi konten? Silahkan tulis catatan dengan jelas">
            <div class="d-block text-center">
                <b-form-textarea v-model="revisionNote"></b-form-textarea>
            </div>
        </b-modal>

        <b-modal ref="my-modal-selesai-campaign" @ok="selesaikanCampaign" ok-title="Ya" cancel-title="Batal" title="Selesaikan Campaign">
            <p>
                Apakah Anda yakin mengubah status campaign ini menjadi "Selesai"?
            </p>
        </b-modal>

        <b-modal ref="my-modal-beri-ulasan" @ok="beriUlasan" ok-title="Ya" cancel-title="Batal" title="Beri Ulasan">
            <p class="text-center">
                <b-form-rating
                    id="rating-lg-no-border"
                    v-model="rating"
                    no-border
                    variant="warning"
                    size="lg"
                    inline
                    class="m-0"
                />
            </p>
            <p>
                <b-form-textarea v-model="message">

                </b-form-textarea>
            </p>
        </b-modal>
    </b-row>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BImgLazy,
    BForm,
    BFormGroup,
    BFormInput,
    BTabs,
    BTab,
    BCardText,
    BFormTextarea,
    BFormRating
} from 'bootstrap-vue'
import CardReview from '../Influencer/CardReview.vue'
import axios from '@axios'
export default {
    name: 'CampaignDetail',
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BButton,
        BImgLazy,
        BForm,
        BFormGroup,
        BFormInput,
        BTabs,
        BTab,
        BCardText,
        BFormTextarea,
        BFormRating,
        CardReview
    },
    data() {
        return {
            campaign: {
                promotionType: { name: null },
                platformType: { name: null },
            },
            contentProposal: {},
            contentPost: {},
            contentProposalID: null,
            revisionNote: null,
            message: '',
            rating: 0,
            services: [],
            review: {
                advertiser: {
                    companyName: null
                },
                rating: null,
                message: null
            }
        }
    },
    mounted() {
        let _ = this
        _.getCampaignData()
        _.getListGDrive()
        _.getInfluencerReview()
    },
    methods: {
        getInfluencerReview() {
            let _ = this
            axios.get('/advertiser/campaigns/'+_.$route.params.id+'/review')
                .then(resp => {
                    let data = resp.data.data
                    _.review = {
                        advertiser: {
                            companyName: _.campaign.advertiser.companyName
                        },
                        rating: data.rating,
                        message: data.message
                    }
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        async getCampaignData() {
            let _ = this
            await axios.get('/advertiser/campaigns/' + _.$route.params.id)
                .then(resp => {
                    let _ = this
                    let campaign = resp.data.data
                    console.log(campaign)
                    _.campaign = campaign
                    _.getContentPost(campaign.id, campaign.services[0].id)
                    _.getBuktiTayang()
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getListGDrive() {
            let _ = this
            axios.get('/advertiser/campaigns/'+_.$route.params.id+'/content-proposals')
                .then(resp => {
                    let _ = this
                    _.contentProposal = resp.data.data.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getContentPost(campaignID, serviceID) {
            let _ = this
            axios.get('/misc/social-media/content-posts/'+campaignID+'/' + serviceID)
                .then(resp => {
                    _.contentPost = resp.data.data.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        approve(contentProposalID) {
            let _ = this
            _.contentProposalID = contentProposalID
            _.$refs['my-modal-approve'].show()
        },
        revisi(contentProposalID) {
            let _ = this
            _.contentProposalID = contentProposalID
            _.$refs['my-modal-revisi'].show()
        },
        setujuiKonten() {
            let _ = this
            axios.put('/advertiser/campaigns/'+_.campaign.id+'/content-proposals/accept', {
                    contentProposalID: _.contentProposalID,
                    revisionNote: _.revisionNote
                })
                .then(resp => {
                    _.getListGDrive()
                    _.getContentPost(_.campaign.id, _.campaign.services[0].id)

                    _.$bvToast.toast('Konten Proposal Disetujui', {
                        title: "Sukses",
                        variant: "success",
                        solid: true,
                    })
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        revisiKonten() {
            let _ = this
            axios.put('/advertiser/campaigns/'+_.campaign.id+'/content-proposals/revision', {
                    contentProposalID: _.contentProposalID,
                    revisionNote: _.revisionNote
                })
                .then(resp => {
                    _.getListGDrive()

                    _.$bvToast.toast('Konten Proposal Direvisi', {
                        title: "Sukses",
                        variant: "warning",
                        solid: true,
                    })
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        showModalSelesaiCampaign() {
            let _ = this
            _.$refs['my-modal-selesai-campaign'].show()
            console.log('show modal selesai campaign : ' + _.campaign.id)
        },
        showModalBeriUlasan() {
            let _ = this
            _.$refs['my-modal-beri-ulasan'].show()
        },
        selesaikanCampaign() {
            let _ = this
            axios.put('/advertiser/campaigns/'+_.campaign.id+'/finish')
                .then(resp => {
                    console.log(resp)
                    _.getCampaignData()

                    _.$bvToast.toast('Campaign Selesai', {
                        title: "Sukses",
                        variant: "success",
                        solid: true,
                    })
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        beriUlasan() {
            let _ = this
            axios.post('/advertiser/campaigns/'+_.campaign.id+'/review', {
                message: _.message,
                rating: _.rating
            })
            .then(resp => {
                _.getInfluencerReview()
                _.$bvToast.toast('Ulasan berhasil dikirim', {
                    title: "Sukses",
                    variant: "success",
                    solid: true,
                })
            })
            .catch(err => {
                console.log(err.response)
            })
        },
        async getBuktiTayang() {
            console.log('getBuktiTayang')
            console.log(this.campaign.id)
            console.log(this.campaign.services)
            this.services = []
            let services = this.campaign.services
            await services.forEach(s => {
                let service = {}
                service.name = s.name
                axios.get('/misc/social-media/content-posts/'+this.campaign.id+'/' + s.id)
                    .then(resp => {
                        let data = resp.data.data
                        return data
                    })
                    .then(d => {
                        service.insight = d
                        this.services.push(service)
                    })
                    .catch(err => {
                        console.log(err.response)
                    })
            })
            
        }
    }
}
</script>